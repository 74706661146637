// extracted by mini-css-extract-plugin
export var ArtistDescription = "DonLeicht-module--ArtistDescription--bZLb5";
export var ArtistInfos = "DonLeicht-module--ArtistInfos--MWyKU";
export var ButtonWrapper = "DonLeicht-module--ButtonWrapper --nUnGg";
export var CardWrapper = "DonLeicht-module--CardWrapper--eZGtA";
export var CarrouselWrapper2 = "DonLeicht-module--CarrouselWrapper2--EqOxL";
export var Citations = "DonLeicht-module--Citations--aLP7o";
export var DescriptionWrapper = "DonLeicht-module--DescriptionWrapper--k0tww";
export var ImageWrapper = "DonLeicht-module--ImageWrapper--qN+a6";
export var LinkWrapper = "DonLeicht-module--LinkWrapper--JiaMz";
export var MobileProtrait = "DonLeicht-module--MobileProtrait--TelqE";
export var More = "DonLeicht-module--More--Djhqb";
export var MoreButton = "DonLeicht-module--MoreButton--PmAvy";
export var NameWrapper = "DonLeicht-module--NameWrapper--God6W";
export var PdpWrapper = "DonLeicht-module--PdpWrapper--chZGh";
export var PhotosWrapper = "DonLeicht-module--PhotosWrapper--n3vF3";
export var ProfilWrapper = "DonLeicht-module--ProfilWrapper--JQKe8";
export var TitleWrapper = "DonLeicht-module--TitleWrapper--yICOJ";
export var Wrapper = "DonLeicht-module--Wrapper--WkRr6";